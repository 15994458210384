// src/components/ProductCard.tsx
import React from 'react';
import { Link } from 'react-router-dom';

// Definir a interface para o card do produto
interface ProductCardProps {
  product: {
    id: string;
    name: string;
    images: string[];
  };
}

const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
  const imageUrl = product.images.length
    ? `https://diggy.allanpimentel.com/api/files/products/${product.id}/${product.images[0]}`
    : 'placeholder.jpg';

  return (
    <div className="product-card">
      <img src={imageUrl} alt={product.name} />
      <h2>{product.name}</h2>
      <Link to={`/product/${product.id}`}>Ver Detalhes</Link>
    </div>
  );
};

export default ProductCard;
