// src/components/ProductDetail.tsx
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import pb from '../pocketbase';

// Interface para o produto detalhado
interface Product {
  id: string;
  name: string;
  description: string;
  images: string[];
  showHome: boolean;
  materialType: string;
  size: string[];
  available: boolean;
  link: string;
  expand: {
    category: {
      name: string;
    };
    tags: { name: string }[];
  };
}

const ProductDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [product, setProduct] = useState<Product | null>(null);

  useEffect(() => {
    // Buscar detalhes do produto
    pb.collection('products')
      .getOne<Product>(id as string, { expand: 'category,tags' })
      .then((res) => setProduct(res))
      .catch((err) => console.error(err));
  }, [id]);

  if (!product) return <div>Carregando...</div>;

  const imageUrls = product.images.map(
    (image) => `https://diggy.allanpimentel.com/api/files/products/${product.id}/${image}`
  );

  return (
    <div>
      <h1>{product.name}</h1>
      <div className="image-gallery">
        {imageUrls.map((url) => (
          <img key={url} src={url} alt={product.name} />
        ))}
      </div>
      <div dangerouslySetInnerHTML={{ __html: product.description }} />
      <p>Material: {product.materialType}</p>
      <p>Tamanhos Disponíveis: {product.size.join(', ')}</p>
      {product.expand?.category && <p>Categoria: {product.expand.category.name}</p>}
      {product.expand?.tags && (
        <p>
          Tags:{' '}
          {product.expand.tags.map((tag) => (
            <span key={tag.name}>{tag.name} </span>
          ))}
        </p>
      )}
      {product.link && (
        <a href={product.link} target="_blank" rel="noopener noreferrer">
          Comprar Agora
        </a>
      )}
    </div>
  );
};

export default ProductDetail;
