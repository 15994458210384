// src/components/Header.tsx

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import pb from '../pocketbase';
import { Resource } from '../interfaces/resource-interface';
import { Category } from '../interfaces/category-interface';

const Header: React.FC = () => {
  const [logoUrl, setLogoUrl] = useState<string>('');
  const [categories, setCategories] = useState<Category[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedCategory, setSelectedCategory] = useState<string>('');

  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const resources = await pb.collection('resources').getFullList<Resource>(1);
        if (resources.length > 0) {
          const resource = resources[8];
          const imageUrl = pb.getFileUrl(resource, resource.image);
          setLogoUrl(imageUrl);
        }
      } catch (error) {
        console.error('Erro ao buscar a logo:', error);
      }
    };

    const fetchCategories = async () => {
      try {
        const categories = await pb.collection('category').getFullList<Category>(200);
        setCategories(categories);
      } catch (error) {
        console.error('Erro ao buscar categorias:', error);
      }
    };

    fetchLogo();
    fetchCategories();
  }, []);

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Adicione aqui o que você quer fazer com a busca e a categoria selecionada
    console.log('Termo de busca:', searchTerm);
    console.log('Categoria selecionada:', selectedCategory);
  };

  return (
    <header className="bg-purple-800 text-white py-4">
      <div className="container mx-auto flex justify-between items-center px-4">
        <Link to="/">
          {logoUrl ? (
            <img src={logoUrl} alt="Logo" className="h-16 w-auto" />
          ) : (
            <h1 className="text-2xl font-bold">Diggy</h1>
          )}
        </Link>
        <form onSubmit={handleSearch} className="flex items-center space-x-2">       
          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="py-2 px-4 rounded-md text-black"
          >
            <option value="">Todas as Categorias</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
          <button type="submit" className="py-2 px-4 bg-fuchsia-700 text-white rounded-md">
            Buscar
          </button>
        </form>
        <nav>
          <ul className="flex space-x-4">
            <li><Link to="/contato" className="hover:text-gray-300">Contato</Link></li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
