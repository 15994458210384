// src/components/Home.tsx
import React, { useEffect, useState } from 'react';
import pb from '../pocketbase';
import ProductCard from './ProductCard';
import Carousel from './Carousel';

// Interface para o produto
interface Product {
  id: string;
  name: string;
  images: string[];
  description: string;
}

const Home: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    pb.collection('products')
      .getFullList<Product>(200, { expand: 'category' })
      .then((res) => setProducts(res))
      .catch((err) => console.error(err));
  }, []);

  return (
    <div>
      <Carousel />
      <h1 className="text-center text-3xl font-bold my-8">Produtos</h1>
      <div className="product-grid container mx-auto px-4 grid grid-cols-1 md:grid-cols-3 gap-8">
        {products.map((product) => (
          <ProductCard key={product.id} product={product} />
        ))}
      </div>
    </div>
  );
};

export default Home;
