// src/components/Contact.tsx

import React from 'react';

const Contact: React.FC = () => {
  return (
    <div className="container mx-auto py-10">
      <h1 className="text-4xl font-bold text-center mb-8">Meus Contatos</h1>
      <div className="flex flex-col items-center space-y-4">
        <a
          href="https://www.instagram.com/seu_usuario"
          target="_blank"
          rel="noopener noreferrer"
          className="text-xl text-blue-500 hover:underline"
        >
          Instagram
        </a>
        <a
          href="https://www.facebook.com/seu_usuario"
          target="_blank"
          rel="noopener noreferrer"
          className="text-xl text-blue-700 hover:underline"
        >
          Facebook
        </a>
        <a
          href="https://wa.me/seu_numero_wpp"
          target="_blank"
          rel="noopener noreferrer"
          className="text-xl text-green-500 hover:underline"
        >
          WhatsApp
        </a>
        <a
          href="https://www.mercadolivre.com.br/perfil/seu_usuario"
          target="_blank"
          rel="noopener noreferrer"
          className="text-xl text-yellow-500 hover:underline"
        >
          Mercado Livre
        </a>
      </div>
    </div>
  );
};

export default Contact;
