// src/components/Carousel.tsx
import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import pb from '../pocketbase';


interface Product {
  id: string;
  name: string;
  images: string[];
  showHome: boolean;
  // Outras propriedades, se necessário
}

const Carousel: React.FC = () => {
  const [highlights, setHighlights] = useState<Product[]>([]);

  useEffect(() => {
    const fetchHighlights = async () => {
      try {
        const products = await pb.collection('products').getFullList<Product>(200, {
          filter: 'showHome = true',
        });
        setHighlights(products);
      } catch (error) {
        console.error('Erro ao buscar os produtos de destaque:', error);
      }
    };

    fetchHighlights();
  }, []);

  return (
    <section className="my-8">
      <h2 className="text-center text-3xl font-bold mb-4">Destaques</h2>
      <Swiper spaceBetween={20} slidesPerView={1} loop={true} >
        {highlights.map((product) => (
          <SwiperSlide key={product.id}>
            <div className="flex flex-col items-center">
              <img
                src={
                  product.images.length > 0
                    ? `https://diggy.allanpimentel.com/api/files/products/${product.id}/${product.images[0]}`
                    : 'placeholder.jpg'
                }
                alt={product.name}
                className="w-full h-64 object-cover"
              />
              <h3 className="text-xl font-semibold mt-4">{product.name}</h3>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default Carousel;
